import './App.css';
import balderImage from './img/even more fucked.jpg';

function App() {
  return (
    <div className="App">
      <img src={balderImage} alt="Balder" className="full-screen-image" />
    </div>
  );
}

export default App;
